.iconoCrear {
  /* background-color: #faf9f8; 
  backgroundColor: '#faf9f8', */
  text-align: center;
  padding: 5px;
}
.panelGeneral {
  display: flex;
  justify-content: center;
  width: 100%;
}
.panelTabla {
  padding: 20px;
}

.gridMenu {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 10px;
  min-width: 100px;
}
