/* ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
} */

::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    background: transparent;
    border: 0px none #fff;
    border-radius: 10px;
    background-color: #cdd2d6;
}

/* ::-webkit-scrollbar-thumb:hover {
    background: #adbac4;
} */

::-webkit-scrollbar-thumb:active {
    background: #adbac4;
}

::-webkit-scrollbar-track {
    /* background: transparent; */
    border: 0px solid #aaa;
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
	background-color: #F5F5F5;
}

::-webkit-scrollbar-track:hover {
    background: #e4e4e4;
}

::-webkit-scrollbar-track:active {
    /* background: #946c6c; */
}

::-webkit-scrollbar-corner {
    background: transparent;
}

body {
    background-color: #fcfcfc;
    padding: 0;
    margin: 0;
}