.container {
  height: 100vh;
  width: 100vw;
}

.container-cropper {
  height: 90%;
  padding: 10px;
}

.cropper {
  height: 90%;
  position: relative;
}

.slider {
  height: 10%;
  display: flex;
  align-items: center;
  margin: auto;
  width: 100%;
}

.container-actions {
  position: fixed;
  bottom: 0;
  margin-bottom: 1rem;
  width: 91%;
}

.container-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttons-left {
  display: flex;
}
